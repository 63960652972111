import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="change-cart"
export default class extends Controller {
  static targets = ["form"]

  change(event) {
    this.formTarget.click();
  }
}
