import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = ["month0", "month1"];
  static values =  { index: Number };

  prevPage() {
    this.month0Target.classList.remove('disable');
    this.month1Target.classList.add('disable');

    const prevLink = this.getPrevPageElement(this.month0Target);
    const nextLink = this.getNextPageElement(this.month0Target);

    prevLink.classList.add('unvisible');
    nextLink.classList.remove('unvisible');
  }

  nextPage() {
    this.month0Target.classList.add('disable');
    this.month1Target.classList.remove('disable');

    const prevLink = this.getPrevPageElement(this.month1Target);
    const nextLink = this.getNextPageElement(this.month1Target);

    prevLink.classList.remove('unvisible');
    nextLink.classList.add('unvisible');
  }

  getPrevPageElement(currentElement) {
    return currentElement.querySelector('[data-action="click -> calendar#prevPage"]');
  }
  getNextPageElement(currentElement) {
    return currentElement.querySelector('[data-action="click -> calendar#nextPage"]');
  }
}

