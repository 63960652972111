import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="top-hamburger"
export default class extends Controller {
  static targets = ["bars", "batsu", "menu"]
  toggle () {
    this.toggleButton();
    this.toggleMenu();
  }

  toggleButton () {
    const bars = this.barsTarget;
    const batsu = this.batsuTarget;
    bars.classList.toggle("check_unvisible");
    batsu.classList.toggle("check_unvisible");
  }

  toggleMenu () {
    const menu = this.menuTarget;
    menu.classList.toggle("brand_menu_visible");
  }
}
