import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="zip-code"
export default class extends Controller {
  static targets = [ "code" ]

  async search () {
    const zip_code = this.codeTarget.value;

    const prefecturesElement = this.element.querySelector("#order_shipping_address_prefectures")
    const cityElement = this.element.querySelector("#order_shipping_address_city")

    const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zip_code}`)
    if (!response.ok) return;

    const result = await response.json();
    if (result.status != 200) return;
    if (result.results.length < 0) return;

    prefecturesElement.value = result.results[0].address1
    cityElement.value = result.results[0].address2 + result.results[0].address3
  }
}
