import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="adm--menu"
export default class extends Controller {
  static targets = ["burgar", "menu"]
  toggle() {
    const burgar = this.burgarTarget;
    const menu = this.menuTarget;

    burgar.classList.toggle("is-active");
    menu.classList.toggle("is-active");
  }
}
