import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    window.scrollTo(0,0);
    document.body.classList.add("disable_scroll");
  }

  disconnect() {
    document.body.classList.remove("disable_scroll");
  }
}
